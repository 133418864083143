<template>
  <Header />
  <div class="registration-form">
    <h2>Registration Form</h2>
    <form @submit.prevent="handleSubmit">
      <h3>Athlete Information</h3>
      <div>
        <label for="name">Name:</label>
        <input type="text" v-model="athleteInfo.name" required />
      </div>

      <div>
        <label for="dob">Date of Birth:</label>
        <input
          type="date"
          v-model="athleteInfo.dob"
          @blur="validateDate"
          required
        />
      </div>
      <div>
        <label for="aadhar">Aadhar card number:</label>
        <input type="number" maxlength="12" v-model="athleteInfo.aadhar" />
      </div>
      <div>
        <label for="age">Age:</label>
        <input type="number" v-model="age" readonly />
      </div>
      <div v-if="age >= 18">
        <label for="contactNo">Contact No:</label>
        <input
          type="text"
          v-model="athleteInfo.contactNo"
          @blur="validatePhone"
          required
        />
        <button
          type="button"
          @click="
            submitOtp();
            sendMobileOtp();
          "
          :disabled="!isPhoneValid"
        >
          Submit OTP
        </button>
      </div>
      <div v-if="age >= 18">
        <label for="password">Password:</label>
        <input
          type="password"
          v-model="athleteInfo.password"
          id="psw"
          name="psw"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          required
        />
        <label for="profession">Profession:</label>
        <input type="text" v-model="athleteInfo.profession" />
      </div>
      <div>
        <label for="gender">Gender:</label>
        <select v-model="athleteInfo.gender" required>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
      <div>
        <label for="allergies">Allergies / Health concerns:</label>
        <textarea v-model="athleteInfo.allergies"></textarea>
      </div>
      <div>
        <label for="enrollment">Enrollment:</label>
        <select v-model="athleteInfo.enrollment" required>
          <option value="Developmental Gymnastics">
            Developmental Gymnastics
          </option>
          <option value="Competitive Gymnastics">Competitive Gymnastics</option>
          <option value="Adult Gymnastics">Adult Gymnastics</option>
          <option value="Adult Gymnastics">Competition</option>
          <option value="Calisthenics or Dance">Calisthenics or Dance</option>
          <option value="Healthy Weight">Healthy Weight</option>
          <option value="Personal Training">Personal Training</option>
        </select>
      </div>
      <h3 v-if="age <= 18">Parents / Guardian Information</h3>
      <div v-if="age <= 18">
        <label for="fatherName">Father's Name:</label>
        <input type="text" v-model="parentInfo.fatherName" />
        <label for="fatherProfession">Father's Profession:</label>
        <input type="text" v-model="parentInfo.fatherProfession" />
      </div>
      <div v-if="age <= 18">
        <label for="fatherMobile">Father's Mobile:</label>
        <input
          type="text"
          v-model="parentInfo.fatherMobile"
          @blur="validatePhone1"
          required
        />
        <button
          type="button"
          @click="
            submitOtp1();
            sendMobileOtp1();
          "
          :disabled="!isPhoneValid"
        >
          Submit OTP
        </button>
      </div>
      <div v-if="age <= 18">
        <label for="password">Password:</label>
        <input
          type="password"
          v-model="athleteInfo.password"
          id="psw"
          name="psw"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
          required
        />
      </div>
      <div v-if="age <= 18">
        <label for="fatherEmail">Father's Email:</label>
        <input
          type="email"
          v-model="parentInfo.fatherEmail"
          id="fatherEmail"
          required
        />
        <span
          v-if="!isValidEmail(parentInfo.fatherEmail) && parentInfo.fatherEmail"
          >Please enter a valid email address.</span
        >
      </div>
      <div v-if="age <= 18">
        <label for="motherName">Mother's Name:</label>
        <input type="text" v-model="parentInfo.motherName" />
      </div>
      <div v-if="age <= 18">
        <label for="motherMobile">Mother's Mobile:</label>
        <input type="text" v-model="parentInfo.motherMobile" />
        <label for="motherProfession">Mother's Profession:</label>
        <input type="text" v-model="parentInfo.motherProfession" />
      </div>
      <div>
        <label for="address">Address:</label>
        <textarea v-model="parentInfo.address" required></textarea>
      </div>
      <div>
        <label for="pinCode">Pin Code:</label>
        <input type="text" v-model="parentInfo.pinCode" required />
      </div>
      <div>
        <label for="emergencyContact">Emergency Contact Name:</label>
        <input type="text" v-model="emergencyContact.name" required />
      </div>
      <div>
        <label for="emergencyContactNo">Emergency Contact No:</label>
        <input type="text" v-model="emergencyContact.contactNo" required />
      </div>

      <div>
        <input type="checkbox" v-model="agreedToTerms" id="terms" required />
        <label for="terms" @click="showConsentDialog"
          >I agree to all terms and conditions</label
        >
      </div>
      <div v-if="showDialog" class="dialog">
        <div class="dialog-content" v-if="age <= 18">
          <span class="close" @click="showDialog = false">&times;</span>
          <h2>Consent Information</h2>
          <p>
            I (Parents/Guardian) hereby give my consent willfully in all
            respects to send my children to participate in The Gymnastics
            School, Gymnastics Activities, sports event, Fitness Classes or any
            other activity, and I (Parents/ Guardian)shall be responsible to
            provide medical assistance and consent that even in the event of any
            accident the necessary treatment and expenditure will be beared by
            me which may be included in the use of anesthetic if required
          </p>
          <p>
            I (Parents/Guardian) also understand that during the course of
            gymnastics activities, sport events, Fitness Classes or any other
            activity an injury may occur in unfortunate circumstances
          </p>
          <p>
            I (Parents/Guardian) shall not blame The Gymnastics School
            Teachers/Coaches/Instructors/Trainers or The Gymnastics School staff
            for such incidents. However, The Gymnastics School
            Teachers/Coaches/Instructors/Trainers or The Gymnastics School Staff
            will pay due care and attention in all aspects in performing the
            Gymnastics Activities, sports event, Fitness Classes or any other
            activity for the betterment for the society , for the betterment of
            an individual child, an adult and society at large.
          </p>
        </div>
        <div class="dialog-content" v-if="age >= 18">
          <span class="close" @click="showDialog = false">&times;</span>
          <h2>Consent Information</h2>
          <p>
            I hereby give my consent willfully in all respects to send myself to
            participate in The Gymnastics School, Gymnastics Activities, sports
            event, Fitness Classes or any other activity, and I shall be
            responsible to provide medical assistance and consent that even in
            the event of any accident the necessary treatment and expenditure
            will be beared by me which may be included in the use of anesthetic
            if required.
          </p>
          <p>
            I also understand that during the course of gymnastics activities,
            sport events, Fitness Classes or any other activity an injury may
            occur in unfortunate circumstances. I shall not blame The Gymnastics
            School Teachers/Coaches/Instructors/Trainers or The Gymnastics
            School staff for such incidents. However, The Gymnastics School
            Teachers/Coaches/Instructors/Trainers or The Gymnastics School Staff
            will pay due care and attention in all aspects in performing the
            Gymnastics Activities, sports event, Fitness Classes or any other
            activity for the betterment for the society , for the betterment of
            an individual child, an adult and society at large.
          </p>
        </div>
      </div>
      <button type="submit" >
        Submit Registration
      </button>
    </form>

    <!-- OTP Dialog -->
    <div v-if="showOtpDialog" class="dialog">
      <h3>Enter OTP</h3>
      <div>
        <input type="text" v-model="otpInput" maxlength="4" />
        <button @click="verifyOtp">Submit</button>
      </div>
    </div>
    <!-- Terms and Conditions Dialog -->
    <!-- <div v-if="showDialog" class="dialog">
      <h2>Consent Information</h2>
        <p>I hereby give my consent willfully in all respects to send my children or myself to participate in The Gymnastics School, Gymnastics Activities, sports event, Fitness Classes or any other activity, and I shall be responsible to provide medical assistance and consent that even in the event of any accident the necessary treatment and expenditure will be beared by me which may be included in the use of anesthetic if required.</p>
        <p>I also understand that during the course of gymnastics activities, sport events, Fitness Classes or any other activity an injury may occur in unfortunate circumstances. I shall not blame The Gymnastics School Teachers/Coaches/Instructors/Trainers or The Gymnastics School staff for such incidents. However, The Gymnastics School Teachers/Coaches/Instructors/Trainers or The Gymnastics School Staff will pay due care and attention in all aspects in performing the Gymnastics Activities, sports event, Fitness Classes or any other activity for the betterment for the society , for the betterment of an individual child, an adult and  society at large.</p>
        <button @click="closeTermsDialog">Close</button>
    </div> -->
  </div>
  <Footer />
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import axios from "axios";
let verificationCode;
export default {
  data() {
    return {
      athleteInfo: {
        name: "",
        contactNo: "",
        dob: "",
        age: "",
        profession: "",
        aadhar: "",
        password: "",
        gender: "Male",
        allergies: "",
        enrollment: "",
      },
      parentInfo: {
        fatherName: "",
        fatherProfession: "",
        fatherMobile: "",
        motherName: "",
        motherMobile: "",
        motherProfession: "",
        address: "",
        pinCode: "",
      },
      emergencyContact: {
        name: "",
        contactNo: "",
      },
      termsAccepted: false,
      showOtpDialog: false,
      showDialog: false,
      otpInput: "",
      isPhoneVerified: false,
      isPhoneValid: false,
      isEmailValid: false,
      isDateValid: false,
      token: "replace with actual value",
      otp: null,
      verificationId: "",
    };
  },
  computed: {
    age() {
      if (this.athleteInfo.dob) {
        const today = new Date();
        const dob = new Date(this.athleteInfo.dob);
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < dob.getDate())
        ) {
          age--;
        }
        return age;
      }
      return "";
    },
  },
  methods: {
    validatePhone() {
      const phonePattern = /^[0-9]{10}$/;
      this.isPhoneValid = phonePattern.test(this.athleteInfo.contactNo);
      if (!this.isPhoneValid) {
        alert("Please enter a valid 10-digit phone number.");
      }
    },
    validatePhone1() {
      const phonePattern = /^[0-9]{10}$/;
      this.isPhoneValid = phonePattern.test(this.parentInfo.fatherMobile);
      if (!this.isPhoneValid) {
        alert("Please enter a valid 10-digit phone number.");
      }
    },
    isValidEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validateDate() {
      const today = new Date();
      const dob = new Date(this.athleteInfo.dob);
      this.isDateValid = dob < today;
      if (!this.isDateValid) {
        alert("Date of birth must be in the past.");
      }
    },
    showConsentDialog() {
      this.showDialog = true;
    },
    closeTermsDialog() {
      this.showDialog = false;
    },
    submitOtp() {
      this.showOtpDialog = true;
    },
    async sendMobileOtp() {
      try {
        const response = await axios.get(
          "https://cpaas.messagecentral.com/auth/v1/authentication/token?customerId=C-A7CE32B3FA3140A&scope=NEW&country=91&key=SGFyc2hAMTk5Nw%3D%3D"
        );
        this.token = response.data.token;
        const options = {
          method: "POST",
          url: "https://cpaas.messagecentral.com/verification/v3/send",
          params: {
            mobileNumber: this.athleteInfo.contactNo,
            flowType: "SMS",
            countryCode: "91",
            otpLength: "4",
          },
          headers: {
            "Content-Type": "application/json",
            authToken: response.data.token,
          },
        };
        await axios
          .request(options)
          .then(function (responseOtp) {
            verificationCode = responseOtp.data.data.verificationId;
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (error) {
        console.error("Error sending mobile:", error);
      }
    },
    submitOtp1() {
      this.showOtpDialog = true;
    },
    async sendMobileOtp1() {
      try {
        const response = await axios.get(
          "https://cpaas.messagecentral.com/auth/v1/authentication/token?customerId=C-A7CE32B3FA3140A&scope=NEW&country=91&key=SGFyc2hAMTk5Nw%3D%3D"
        );
        this.token = response.data.token;
        const options = {
          method: "POST",
          url: "https://cpaas.messagecentral.com/verification/v3/send",
          params: {
            mobileNumber: this.parentInfo.fatherMobile,
            flowType: "SMS",
            countryCode: "91",
            otpLength: "4",
          },
          headers: {
            "Content-Type": "application/json",
            authToken: response.data.token,
          },
        };
        await axios
          .request(options)
          .then(function (responseOtp) {
            verificationCode = responseOtp.data.data.verificationId;
          })
          .catch(function (error) {
            console.error(error);
          });
      } catch (error) {
        console.error("Error sending mobile:", error);
      }
    },

    async verifyOtp() {
      try {
        const options = {
          method: "GET",
          url: "https://cpaas.messagecentral.com/verification/v3/validateOtp",
          params: {
            verificationId: verificationCode,
            code: this.otpInput,
          },
          headers: {
            "Content-Type": "application/json",
            authToken: this.token,
          },
        };
        await axios
          .request(options)
          .then(function (response) {
            if (response.data.responseCode == 200) {
              console.log("successful");
            }
            if (response.responseCode == 500) {
              alert("Invalid OTP. Please try again.");
            }
          })
          .catch(function (error) {
            console.error(error);
          });
        this.isPhoneVerified = true;
        this.showOtpDialog = false;
      } catch (error) {
        alert("Invalid OTP. Please try again.");
        console.error("Error sending mobile:", error);
      }
    },

    handleSubmit() {
      //   if (
      //     this.termsAccepted &&
      //     this.isPhoneVerified &&
      //     this.isEmailValid &&
      //     this.isDateValid
      // //   ) {
        console.log("harsh was here----------------",this.athleteInfo)
        console.log("gazal was here----------------",this.emergencyContact)
        console.log("varsh was here----------------",this.parentInfo)
        const options = {
        method: "PUT",
        url: "http://3.110.151.145:8009/StudentReg",
        headers: {
          "Content-Type": "application/json",
          "User-Agent": "insomnia/2023.5.8",
        },
        data: {
          athleteInfo:this.athleteInfo,
          emergencyContact: this.emergencyContact,
          parentInfo : this.parentInfo}
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
        })
        .catch(function (error) {
          console.error(error);
        });
      // onclick = "window.location.href = '/';";
      // window.location.href = "http://localhost:8080"; // Redirect to Google
      //   } else {
      //     alert("Please ensure all fields are valid and terms are accepted.");
      //   }
    },
  },
  name: "SignUp",
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.registration-form {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
}
h1,
h2 {
  text-align: center;
}
label {
  display: block;
  margin: 10px 0 5px;
}
input,
select,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000;
}

.dialog h3 {
  margin-top: 0;
}

.dialog button {
  margin-top: 10px;
}
button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #218838;
}
</style>
